// /src/FormComponent.js
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadCSVFile } from './UploadService'; // Adjust the path as needed

function FormComponent({ onResponse }) {
  const [apiKey, setApiKey] = useState('');
  const [file, setFile] = useState(null);
  const [customerId, setCustomerId] = useState('');
  const [oemName, setOemName] = useState('lux');
  const [region, setRegion] = useState('af-south-1');  // Added state for region
  const [location, setLocation] = useState('CapeTown');  // Added state for location

  // Callback when a file is selected or dropped.
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert('Please upload a file before submitting');
      return;
    }

    if (!apiKey) {
      alert('Please provide an API key.');
      return;
    }

    try {
      // Use the uploadCSVFile SDK function to perform both steps:
      // 1. Get the presigned URL from /upload_train.
      // 2. Upload the file to S3 using that URL.
      const result = await uploadCSVFile({
        // The endpoint is set to your Lambda integration endpoint for presigned URLs.
        apiEndpoint: 'https://yn058ezh38.execute-api.af-south-1.amazonaws.com/test/upload_train',
        apiKey: apiKey,
        file: file,
        customerId: customerId,          // Will be sent as "customer_id"
        region: region,                  // User-defined region (e.g., "af-south-1")
        location: location,              // User-defined location (e.g., "CapeTown")
        manufacturer: oemName,           // Device manufacturer (e.g., "lux" or "macrocomm")
        filename: file.name,             // The filename of the CSV file
      });
      onResponse(result);
    } catch (error) {
      onResponse(error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="apiKey">API Key:</label>
        <input
          type="text"
          id="apiKey"
          name="apiKey"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          placeholder="Enter your API key"
        />

        <label htmlFor="customerId">Customer ID:</label>
        <input
          type="text"
          id="customerId"
          name="customerId"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
        />

        <label htmlFor="region">Region:</label>
        <input
          type="text"
          id="region"
          name="region"
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          placeholder="Enter region (e.g., af-south-1)"
        />

        <label htmlFor="location">Location:</label>
        <input
          type="text"
          id="location"
          name="location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Enter location (e.g., CapeTown)"
        />

        <label htmlFor="oemName">Device Manufacturer:</label>
        <select
          id="oemName"
          name="oemName"
          value={oemName}
          onChange={(e) => setOemName(e.target.value)}
        >
          <option value="lux">Lux</option>
          <option value="macrocomm">Macrocomm</option>
        </select>

        <label htmlFor="uploadFile">Upload File:</label>
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} id="uploadFile" name="uploadFile" />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>

        {file && <p>Selected file: {file.name}</p>}

        <button className="submit-button" type="submit">Submit</button>
      </form>
    </div>
  );
}

export default FormComponent;

/*
Changelog:
----------
1. Added new state variables for "region" and "location" with default values.
2. Updated the JSX to include input fields for both "region" and "location", allowing users to specify these values.
3. In the handleSubmit function, the SDK function `uploadCSVFile` now receives the parameters "customerId", "region", "location", "manufacturer", and "filename".
   - The SDK will convert "customerId" to the "customer_id" query parameter required by the /upload_train endpoint.
4. These changes ensure that the query parameters match exactly what is defined in your Swagger for the /upload_train endpoint.
*/
