
import axios from 'axios';

/**
 * Uploads a CSV file to S3 by first obtaining a presigned URL from the Lambda endpoint,
 * and then using that URL to perform the file upload.
 *
 * @param {Object} config - Configuration for the upload.
 * @param {string} config.apiEndpoint - The URL of the Lambda endpoint that generates the presigned URL.
 * @param {string} config.apiKey - The API key required by the Lambda endpoint.
 * @param {File|Blob} config.file - The CSV file to be uploaded.
 * @param {string} config.customerId - The customer ID.
 * @param {string} config.region - The AWS region (e.g., "af-south-1").
 * @param {string} config.location - The location (e.g., "CapeTown").
 * @param {string} config.manufacturer - The manufacturer (e.g., "lux" or "macrocomm").
 * @param {string} config.filename - The filename (e.g., "data.csv").
 * @returns {Promise<Object>} - Resolves to an object with a success message and S3 info.
 */
export async function uploadCSVFile(config) {
  const {
    apiEndpoint,
    apiKey,
    file,
    customerId,
    region,
    location,
    manufacturer,
    filename,
  } = config;

  // Build the presigned URL endpoint with all required query parameters.
  // (Make sure to URL-encode each parameter.)
  const presignedUrlEndpoint = `${apiEndpoint}?customer_id=${encodeURIComponent(customerId)}&region=${encodeURIComponent(region)}&location=${encodeURIComponent(location)}&manufacturer=${encodeURIComponent(manufacturer)}&filename=${encodeURIComponent(filename)}`;

  // Step 1: Request the presigned URL from the Lambda endpoint.
  const presignedResponse = await axios({
    method: 'post',
    url: presignedUrlEndpoint,
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json',
    },
  });

  if (presignedResponse.status !== 200) {
    throw new Error(
      `Failed to obtain presigned URL: ${presignedResponse.status} ${presignedResponse.statusText}`
    );
  }

  const presignedData = presignedResponse.data;
  if (!presignedData.presigned_url) {
    throw new Error('No presigned_url returned from Lambda endpoint.');
  }

  const presignedUrl = presignedData.presigned_url;

  // Step 2: Use the presigned URL to upload the file directly to S3.
  // The presigned URL was generated with the expectation that the Content-Type is "text/csv".
  const uploadResponse = await axios({
    method: 'put',
    url: presignedUrl,
    data: file,
    headers: {
      'Content-Type': 'text/csv',
    },
  });

  if (uploadResponse.status !== 200 && uploadResponse.status !== 204) {
    throw new Error(
      `File upload failed: ${uploadResponse.status} ${uploadResponse.statusText}`
    );
  }

  return {
    message: 'File uploaded successfully',
    s3Info: {
      bucket: presignedData.upload_bucket,
      key: presignedData.upload_key,
    },
  };
}

/*
Changelog:
----------
1. Created the function `uploadCSVFile` that encapsulates:
   - Calling the Lambda endpoint (configured via `apiEndpoint`) to generate a presigned URL.
   - Using the returned presigned URL to perform a PUT request (with Content-Type "text/csv") to S3.
2. Query parameters (customer_id, region, location, manufacturer, filename) are URL-encoded and appended to the endpoint URL.
3. Detailed error handling is provided for both steps.
4. Inline comments explain the function’s purpose, parameters, and process.
*/
