import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import { FiSend, FiPlus } from "react-icons/fi"; // Import send and plus icons

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [files, setFiles] = useState([]);
  const chatEndRef = useRef(null);

  useEffect(() => {
    document.body.classList.add("chat-active");
    return () => document.body.classList.remove("chat-active");
  }, []);

  const handleSendMessage = () => {
    if (input.trim() !== "" || files.length > 0) {
      setMessages([...messages, { text: input, sender: "user", files }]);
      setInput("");
      setFiles([]);

      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "This is a response from AI.", sender: "bot" },
        ]);
      }, 1000);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const allowedTypes = ["text/plain", "application/pdf", "application/msword", "image/jpeg", "image/png"];

    const validFiles = selectedFiles.filter((file) => allowedTypes.includes(file.type));

    if (validFiles.length > 0) {
      setFiles(validFiles);
    }
  };

  const triggerFileUpload = () => {
    document.getElementById("file-upload").click();
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-header"><h2>ClimatIQ</h2></div>

      <div className="chat-messages">
        {messages.length === 0 ? (
          <div className="chat-placeholder">Let's talk energy policy!</div>
        ) : (
          messages.map((msg, index) => (
            <div key={index} className={`chat-message ${msg.sender}`}>
              <p>{msg.text}</p>
              {msg.files &&
                msg.files.map((file, i) => (
                  <p key={i} className="chat-file">
                    📎 {file.name}
                  </p>
                ))}
            </div>
          ))
        )}
        <div ref={chatEndRef} />
      </div>

      <div className="chat-input-container">
        <button onClick={triggerFileUpload} className="file-upload-button">
          <FiPlus />
        </button>
        <input
          type="file"
          id="file-upload"
          multiple
          accept=".txt,.pdf,.doc,.docx,image/*"
          onChange={handleFileChange}
          className="file-input"
        />

        <textarea
          placeholder="Message AI..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          className="chat-textarea"
          rows="1"
        />

        <button onClick={handleSendMessage} className="send-button">
          <FiSend />
        </button>
      </div>
    </div>
  );
};

export default Chatbot;
